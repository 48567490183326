import React from "react";
import { BigPictureGallery } from "react-bigpicture";
import "./style.css";




function PortfolioImages() {

    return (
        <div className="borderPortfolio">
            <h1 className="Gallery">Portfolio Gallery</h1>
            <div className="portfolioImagesback">
                <BigPictureGallery loop>
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/wMvhwtO.jpg"
                        src="https://i.imgur.com/wMvhwtOm.jpg"
                        caption="Heyyyy"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/A2bZjAD.jpg"
                        src="https://i.imgur.com/A2bZjADm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/CJvfXuT.jpg"
                        src="https://i.imgur.com/CJvfXuTm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/dVHYFfJ.jpg"
                        src="https://i.imgur.com/dVHYFfJm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/StwBskN.jpg"
                        src="https://i.imgur.com/StwBskNm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/fDsbcx7.jpg"
                        src="https://i.imgur.com/fDsbcx7m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/UI1CR61.jpg"
                        src="https://i.imgur.com/UI1CR61m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/SBccE3e.jpg"
                        src="https://i.imgur.com/SBccE3em.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/eChQRcx.jpg"
                        src="https://i.imgur.com/eChQRcxm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/QzSiKE4.jpg"
                        src="https://i.imgur.com/QzSiKE4m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/yI9sVfO.jpg"
                        src="https://i.imgur.com/yI9sVfOm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/38670Jn.jpg"
                        src="https://i.imgur.com/38670Jnm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/PV9BFbl.jpg"
                        src="https://i.imgur.com/PV9BFblm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/KtxmNOe.jpg"
                        src="https://i.imgur.com/KtxmNOem.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/zNp7p48.jpg"
                        src="https://i.imgur.com/zNp7p48m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/UmzUJG9.jpg"
                        src="https://i.imgur.com/UmzUJG9m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/7hHlUzP.jpg"
                        src="https://i.imgur.com/7hHlUzPm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/T4knrDB.jpg"
                        src="https://i.imgur.com/T4knrDBm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/nAwELgm.jpg"
                        src="https://i.imgur.com/nAwELgmm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/lG1LpZQ.jpg"
                        src="https://i.imgur.com/lG1LpZQm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/YKim6TW.jpg"
                        src="https://i.imgur.com/YKim6TWm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/YzMMCtc.jpg"
                        src="https://i.imgur.com/YzMMCtcm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ixSuHvg.jpg"
                        src="https://i.imgur.com/ixSuHvgm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/KbXBcvo.jpg"
                        src="https://i.imgur.com/KbXBcvom.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ndw6wJx.jpg"
                        src="https://i.imgur.com/ndw6wJxm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/QN4x7HU.jpg"
                        src="https://i.imgur.com/QN4x7HUm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/25AryIH.jpg"
                        src="https://i.imgur.com/25AryIHm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ELfQtt7.jpg"
                        src="https://i.imgur.com/ELfQtt7m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/OoOS6xn.jpg"
                        src="https://i.imgur.com/OoOS6xnm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ccC7Xo5.jpg"
                        src="https://i.imgur.com/ccC7Xo5m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/B5xLCSE.jpg"
                        src="https://i.imgur.com/B5xLCSEm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/gbI6rGT.jpg"
                        src="https://i.imgur.com/gbI6rGTm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/fVGH6U5.jpg"
                        src="https://i.imgur.com/fVGH6U5m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/5knSzcx.jpg"
                        src="https://i.imgur.com/5knSzcxm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/RMq0vRA.jpg"
                        src="https://i.imgur.com/RMq0vRAm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/MJFfUYs.jpg"
                        src="https://i.imgur.com/MJFfUYsm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/aw8ZYIs.jpg"
                        src="https://i.imgur.com/aw8ZYIsm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/lpEYcQt.jpg"
                        src="https://i.imgur.com/lpEYcQtm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/n1Dib0Z.jpg"
                        src="https://i.imgur.com/n1Dib0Zm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/Zz79AKp.jpg"
                        src="https://i.imgur.com/Zz79AKpm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/6nRVINz.jpg"
                        src="https://i.imgur.com/6nRVINzm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/nvZZa21.jpg"
                        src="https://i.imgur.com/nvZZa21m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/Bc6oCIb.jpg"
                        src="https://i.imgur.com/Bc6oCIbm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/8tu9X53.jpg"
                        src="https://i.imgur.com/8tu9X53m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/3dG26vf.jpg"
                        src="https://i.imgur.com/3dG26vfm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/YIa2wwP.jpg"
                        src="https://i.imgur.com/YIa2wwPm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/qrswI4J.jpg"
                        src="https://i.imgur.com/qrswI4Jm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/wBozIOo.jpg"
                        src="https://i.imgur.com/wBozIOom.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/THyyUpU.jpg"
                        src="https://i.imgur.com/THyyUpUm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/PTtBTbL.jpg"
                        src="https://i.imgur.com/PTtBTbLm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/LN7QTYB.jpg"
                        src="https://i.imgur.com/LN7QTYBm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/Ua2j3Hn.jpg"
                        src="https://i.imgur.com/Ua2j3Hnm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/U1Mp4rK.jpg"
                        src="https://i.imgur.com/U1Mp4rKm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/qp4SlrG.jpg"
                        src="https://i.imgur.com/qp4SlrGm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/OcIkk7U.jpg"
                        src="https://i.imgur.com/OcIkk7Um.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/bT4AaVs.jpg"
                        src="https://i.imgur.com/bT4AaVsm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/n5LPf9L.jpg"
                        src="https://i.imgur.com/n5LPf9Lm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/hdwBo7l.jpg"
                        src="https://i.imgur.com/hdwBo7lm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/lCa6vq3.jpg"
                        src="https://i.imgur.com/lCa6vq3m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/rwOfVzo.jpg"
                        src="https://i.imgur.com/rwOfVzom.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/HYusjHc.jpg"
                        src="https://i.imgur.com/HYusjHcm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/MBFAWS0.jpg"
                        src="https://i.imgur.com/MBFAWS0m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/i7BUWTy.jpg"
                        src="https://i.imgur.com/i7BUWTym.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/qxOHZDS.jpg"
                        src="https://i.imgur.com/qxOHZDSm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/xevfgiu.jpg"
                        src="https://i.imgur.com/xevfgium.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/zxXbDpi.jpg"
                        src="https://i.imgur.com/zxXbDpim.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/5CTUtpv.jpg"
                        src="https://i.imgur.com/5CTUtpvm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/vDSvcz1.jpg"
                        src="https://i.imgur.com/vDSvcz1m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/CDZhQU2.jpg"
                        src="https://i.imgur.com/CDZhQU2m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/TQ4CNu2.jpg"
                        src="https://i.imgur.com/TQ4CNu2m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/3CjCK8b.jpg"
                        src="https://i.imgur.com/3CjCK8bm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ubneQSP.jpg"
                        src="https://i.imgur.com/ubneQSPm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/3UXLzgn.jpg"
                        src="https://i.imgur.com/3UXLzgnm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/7glEenb.jpg"
                        src="https://i.imgur.com/7glEenbm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/sPCOXBJ.jpg"
                        src="https://i.imgur.com/sPCOXBJm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/JdCGIZZ.jpg"
                        src="https://i.imgur.com/JdCGIZZm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/Bb8lPu0.jpg"
                        src="https://i.imgur.com/Bb8lPu0m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/RHQbaVF.jpg"
                        src="https://i.imgur.com/RHQbaVFm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/McA9DcB.jpg"
                        src="https://i.imgur.com/McA9DcBm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/tLkyXCN.jpg"
                        src="https://i.imgur.com/tLkyXCNm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/XxNbaRs.jpg"
                        src="https://i.imgur.com/XxNbaRsm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/fHJCxY9.jpg"
                        src="https://i.imgur.com/fHJCxY9m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/NnFxqrt.jpg"
                        src="https://i.imgur.com/NnFxqrtm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/lkMAGsk.jpg"
                        src="https://i.imgur.com/lkMAGskm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/hjUs2eM.jpg"
                        src="https://i.imgur.com/hjUs2eMm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/p8aJ9YE.jpg"
                        src="https://i.imgur.com/p8aJ9YEm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/OtutZTx.jpg"
                        src="https://i.imgur.com/OtutZTxm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/204iSUS.jpg"
                        src="https://i.imgur.com/204iSUSm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ufeRds9.jpg"
                        src="https://i.imgur.com/ufeRds9m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/A2YGlne.jpg"
                        src="https://i.imgur.com/A2YGlnem.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/my6Hv4V.jpg"
                        src="https://i.imgur.com/my6Hv4Vm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/toSTp8V.jpg"
                        src="https://i.imgur.com/toSTp8Vm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/KWSDsf0.jpg"
                        src="https://i.imgur.com/KWSDsf0m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/GUeuUYA.jpg"
                        src="https://i.imgur.com/GUeuUYAm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/eR0sWfD.jpg"
                        src="https://i.imgur.com/eR0sWfDm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/BiiOys4.jpg"
                        src="https://i.imgur.com/BiiOys4m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/fPEAUxp.jpg"
                        src="https://i.imgur.com/fPEAUxpm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/qKgnwqU.jpg"
                        src="https://i.imgur.com/qKgnwqUm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/DtlYwwy.jpg"
                        src="https://i.imgur.com/DtlYwwym.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/yPZxvta.jpg"
                        src="https://i.imgur.com/yPZxvtam.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/ornluyi.jpg"
                        src="https://i.imgur.com/ornluyim.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/6JIbpxk.jpg"
                        src="https://i.imgur.com/6JIbpxkm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/abPDVYA.jpg"
                        src="https://i.imgur.com/abPDVYAm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/C9qm4UE.jpg"
                        src="https://i.imgur.com/C9qm4UEm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/OIMYFK8.jpg"
                        src="https://i.imgur.com/OIMYFK8m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/lJ3redM.jpg"
                        src="https://i.imgur.com/lJ3redMm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/kW8C7Pp.jpg"
                        src="https://i.imgur.com/kW8C7Ppm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/8WG9jj9.jpg"
                        src="https://i.imgur.com/8WG9jj9m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/pptR66r.jpg"
                        src="https://i.imgur.com/pptR66rm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/xo5LsUq.jpg"
                        src="https://i.imgur.com/xo5LsUqm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/zNwO3fQ.jpg"
                        src="https://i.imgur.com/zNwO3fQm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/y4m1puh.jpg"
                        src="https://i.imgur.com/y4m1puhm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/RZ2CbjJ.jpg"
                        src="https://i.imgur.com/RZ2CbjJm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/D76lfOL.jpg"
                        src="https://i.imgur.com/D76lfOLm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/dOAIcLq.jpg"
                        src="https://i.imgur.com/dOAIcLqm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/3Gd7Dap.jpg"
                        src="https://i.imgur.com/3Gd7Dapm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/0Fd1W36.jpg"
                        src="https://i.imgur.com/0Fd1W36m.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/mhekRsJ.jpg"
                        src="https://i.imgur.com/mhekRsJm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/8H8jnsC.jpg"
                        src="https://i.imgur.com/8H8jnsCm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/qYxi7hE.jpg"
                        src="https://i.imgur.com/qYxi7hEm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/6T5xhDR.jpg"
                        src="https://i.imgur.com/6T5xhDRm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/mc4PH8u.jpg"
                        src="https://i.imgur.com/mc4PH8um.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/NpYpxiN.jpg"
                        src="https://i.imgur.com/NpYpxiNm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/RXkgFmQ.jpg"
                        src="https://i.imgur.com/RXkgFmQm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/roQCR0E.jpg"
                        src="https://i.imgur.com/roQCR0Em.jpg"
                        alt=""
                    />

                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/FBtnkKY.jpg"
                        src="https://i.imgur.com/FBtnkKYm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/O6mxk2H.jpg"
                        src="https://i.imgur.com/O6mxk2Hm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/aJJk4RU.jpg"
                        src="https://i.imgur.com/aJJk4RUm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/6PO9jlD.jpg"
                        src="https://i.imgur.com/6PO9jlDm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/CWwgyKM.jpg"
                        src="https://i.imgur.com/CWwgyKMm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/SmkmqrQ.jpg"
                        src="https://i.imgur.com/SmkmqrQm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/vjZPS7h.jpg"
                        src="https://i.imgur.com/vjZPS7hm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/aZMhZNi.jpg"
                        src="https://i.imgur.com/aZMhZNim.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/cpajapM.jpg"
                        src="https://i.imgur.com/cpajapMm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/2d2QOmz.jpg"
                        src="https://i.imgur.com/2d2QOmzm.jpg"
                        alt=""
                    />

                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/HAofKIo.jpg"
                        src="https://i.imgur.com/HAofKIom.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/upRmr7N.jpg"
                        src="https://i.imgur.com/upRmr7Nm.jpg"
                        alt=""
                    />

                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/RlgZZPe.jpg"
                        src="https://i.imgur.com/RlgZZPem.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/BiljT6j.jpg"
                        src="https://i.imgur.com/BiljT6jm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/XYsM6oI.jpg"
                        src="https://i.imgur.com/XYsM6oIm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/Uola3wM.jpg"
                        src="https://i.imgur.com/Uola3wMm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/GqL1O4T.jpg"
                        src="https://i.imgur.com/GqL1O4Tm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/kzkvcBL.jpg"
                        src="https://i.imgur.com/kzkvcBLm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/IUwJ0nB.jpg"
                        src="https://i.imgur.com/IUwJ0nBm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/3wvTd0M.jpg"
                        src="https://i.imgur.com/3wvTd0Mm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/3iCg5tC.jpg"
                        src="https://i.imgur.com/3iCg5tCm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/dKEFhwD.jpg"
                        src="https://i.imgur.com/dKEFhwDm.jpg"
                        alt=""
                    />
                    <img
                        className="Images-Portfolio"
                        zoomedSrc="https://i.imgur.com/SZFCks3.jpg"
                        src="https://i.imgur.com/SZFCks3m.jpg"
                        alt=""
                    />



                </BigPictureGallery>
            </div>



        </div>
    );

}

export default PortfolioImages;