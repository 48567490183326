import React, { Component } from "react";
import PortfolioImages from "../components/PortfolioImages";



class ImagesPortfolio extends Component {
  state = {
    
  };

  
  
  render() {
    
    return (
      
      
        <PortfolioImages />
      
    );
  }
}

export default ImagesPortfolio;