import React from "react";
import "./style.css";
import Image from "../../images/8.jpg";

function AboutImage() {
    return (

        
            
            <img className="img fluid aboutImg" src={Image} alt="Porfolio Not Found"></img>
            
           
        

    );
}

export default AboutImage;