import React, { Component } from "react";
import Portfolio1 from "../components/Portfolio1";



class Portfolio extends Component {
  state = {
    
  };

  
  
  render() {
    
    return (
      
      
        <Portfolio1 />
      
    );
  }
}

export default Portfolio;
